const giftcardForm = $('.js-giftcards-form');
const sendButton = $('.js-giftcard-button_send button');
const clearButton = $('.js-giftcard-button_clear button');
const pinInfoBlock = $('.js-giftcard-pin-info');
const resultBlock = $('.js-giftcard-result');
const balanceField = $('.js-giftcard-balance');
const recaptcha = $('#js-g-recaptcha-giftcard');
const cbError = $('.cb-error-message');
const recaptchaFeedback = $('.js-g-recaptcha-feedback');

module.exports = {
    init() {
        var recaptchaRef;

        this.reCaptchaSuccess = false;
        const verifyCallback = () => {
            this.reCaptchaSuccess = true;
            recaptchaFeedback.hide();
        };
        const expiredCallback = () => {
            setTimeout(() => {
                window.grecaptcha.reset(recaptchaRef);
                this.reCaptchaSuccess = false;
            }, 0); // adds recaptcha reset to the end of queue
        };
        window.addEventListener('load', function () {
            if (recaptcha.html() === '') {
                recaptchaRef = window.grecaptcha.render(recaptcha[0], {
                    sitekey: recaptcha.data('sitekey'),
                    callback: verifyCallback,
                    'expired-callback': expiredCallback
                });

                recaptcha.data('recaptcha-ref', recaptchaRef);
            }
        });

        cbError.hide();
        resultBlock.hide();
        recaptchaFeedback.hide();
        clearButton.hide();
        const locale = $('html').attr('lang');
        const scriptTag = document.createElement('script');
        scriptTag.setAttribute('src', '//www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=' + locale);
        document.getElementsByTagName('body')[0].appendChild(scriptTag);
        giftcardForm.submit((e) => {
            const $form = $(e.target);
            e.preventDefault();
            recaptchaFeedback.hide();
            const url = $form.attr('action');
            if (!this.reCaptchaSuccess) {
                return recaptchaFeedback.show();
            }
            $('.js-gift-cards').trigger('giftcard:submit', e);
            $form.spinner().start();
            $('.cb-error-message-text').text('');
            $('.cb-error-message').hide().addClass('h-hidden');
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success(data) {
                    const {
                        success,
                        serverErrors,
                        fieldErrors,
                        result
                    } = data;
                    $form.spinner().stop();
                    if (success) {
                        sendButton.hide();
                        clearButton.show();
                        pinInfoBlock.hide();
                        balanceField.text(result.balance);
                        resultBlock.show();
                    } else {
                        if (fieldErrors && Object.keys(fieldErrors).length) {
                            Object.keys(fieldErrors).forEach((inputName) => {
                                $(`[name="${inputName}"]`)
                                    .next('.invalid-feedback')
                                    .text(fieldErrors[inputName])
                                    .show();
                            });
                        }

                        if (serverErrors && serverErrors.length) {
                            let errorMessage = '';
                            serverErrors.forEach((error) => {
                                errorMessage += error;
                            });
                            $('.cb-error-message-text').text(errorMessage);
                            $('.cb-error-message').show().removeClass('h-hidden');
                        }
                    }
                },
                error(err) {
                    $form.spinner().stop();
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }

                    if (err.responseJSON && err.responseJSON.message) {
                        $('.cb-error-message-text').text(err.responseJSON.message);
                        $('.cb-error-message').show().removeClass('h-hidden');
                    }
                }
            });
            return false;
        });

        $('#checkBalanceModal').on('dialog:closed', () => {
            this.resetForm();
        });
    },

    clearBtn() {
        clearButton.on('click', (e) => {
            e.preventDefault();
            this.resetForm();
        });
    },
    resetForm() {
        giftcardForm.find('input[type=text]').val('');
        sendButton.show();
        clearButton.hide();
        pinInfoBlock.show();
        resultBlock.hide();
        cbError.hide();
        if (window.grecaptcha && window.grecaptcha.reset) {
            window.grecaptcha.reset(recaptcha.data('recaptcha-ref'));
        } else if (window.grecaptcha && window.grecaptcha.ready) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.reset(recaptcha.data('recaptcha-ref'));
            });
        }
        this.reCaptchaSuccess = false;
    }
};
